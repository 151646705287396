<template>
  <div class="wrapper">
    <div class="logo">
      <img src="@/assets/logo.png" alt="" @click="$router.push('/')" />
    </div>
    <div class="menu-list">
      <div class="menu-item" @click="$router.push('/')">
        <div class="menu-title">首页</div>
        <div class="en-title">HOME</div>
        <div class="menu-hover"></div>
      </div>
      <div class="menu-item" v-for="(item, index) in menuList" :key="index">
        <div
          class="menu-main"
          @click="$router.push({ name: `${item.routeName}` })"
        >
          <div class="menu-title">{{ item.title }}</div>
          <div class="en-title">{{ item.enTitle }}</div>
          <div class="menu-hover"></div>
        </div>
        <div class="menu-content">
          <ul>
            <li
              v-for="(ele, index) in item.content"
              :key="index"
              @click="
                $router.push({
                  name: item.routeName,
                  query: {
                    name: ele.title
                  }
                })
              "
            >
              {{ ele.title }}
            </li>
          </ul>
        </div>
      </div>

      <div class="menu-item" @click="$router.push('/contact')">
        <div class="menu-title">联系方式</div>
        <div class="en-title">CONTACT US</div>
        <div class="menu-hover"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data() {
    return {
      menuList: [
        {
          title: '关于习创',
          enTitle: 'ABOUT US',
          content: [
            { title: '公司简介' },
            { title: '发展历程' },
            { title: '组织架构' }
          ],

          routeName: 'about'
        },
        {
          title: '服务',
          enTitle: 'SERVICES',
          content: [
            { title: '主营业务' },
            { title: '营销网络' },
            { title: '产品介绍' },
            { title: '核心技术' },
            { title: '服务项目' }
          ],
          routeName: 'services'
        },
        {
          title: '文化及荣誉',
          enTitle: 'CULTURE',
          content: [
            { title: '品牌价值' },
            { title: '文化理念' },
            { title: '团队风采' },
            { title: '获得荣誉' },
            { title: '合作伙伴' }
          ],
          routeName: 'culture'
        },
        {
          title: '产品介绍',
          enTitle: 'PRODUCTS',
          content: [
            { title: 'VCT简介' },
            { title: 'VCT架构' },
            { title: 'VCT硬件配置' },
            { title: 'VCT功能特性' },
            { title: 'VCT应用场景' }
          ],
          routeName: 'products'
        }
      ]
    };
  },
  methods: {
    handler() {
      console.log(ele);
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    height: 100%;
    cursor: pointer;
    img {
      height: 100%;
    }
  }
  .menu-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    & > :first-child,
    & > :last-child {
      cursor: pointer;
    }
    .menu-item {
      position: relative;
      height: 100%;
      width: 130px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .menu-title {
        font-size: 17px;
        font-weight: 800;
        color: #213547;
        white-space: nowrap;
      }
      .en-title {
        font-size: 13px;
      }

      .menu-hover {
        display: none;
        position: absolute;
        margin: 0 auto;
        bottom: 0;
        height: 5px;
        width: 100px;
        background-color: rgba(0, 132, 206);
      }
      &:hover .menu-hover {
        display: block;
      }
      .menu-main {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .menu-title {
          font-size: 17px;
          font-weight: 800;
          color: #213547;
          white-space: nowrap;
        }
        .en-title {
          font-size: 13px;
        }

        .menu-hover {
          display: none;
          position: absolute;
          margin: 0 auto;
          bottom: 0;
          height: 5px;
          width: 100px;
          background-color: rgba(0, 132, 206);
        }
        &:hover .menu-hover {
          display: block;
        }
      }
      .menu-content {
        box-sizing: border-box;
        position: absolute;
        width: 130px;
        min-height: 100px;
        left: 0;
        top: 100px;
        padding: 12px;
        border: 1px solid transparent;
        border-radius: 8px;
        background: #ffffff;
        box-shadow: 0 12px 32px rgba(0, 0, 0, 0.1),
          0 2px 6px rgba(0, 0, 0, 0.08);
        opacity: 0;
        visibility: hidden;
        transform: translateY(-5px);
        transition: opacity 0.25s, visibility 0.25s, transform 0.25s;
        ul {
          text-align: center;
          li {
            font-size: 15px;
            font-weight: 400;
            line-height: 35px;
            cursor: pointer;
            &:hover {
              color: rgba(0, 132, 206);
            }
          }
        }
      }
      &:hover .menu-content {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }
}
</style>