import Vue from 'vue';
import VueRouter from 'vue-router';


import Index from '@/views/index/Index.vue';
import Home from '@/views/home/Index.vue';

const About = () => import('@/views/about/Index.vue');
const Services = () => import('@/views/services/Index.vue');
const Culture = () => import('@/views/culture/Index.vue');
const Products = () => import('@/views/products/Index.vue');
const Contact = () => import('@/views/contact/Index.vue');

Vue.use(VueRouter);


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const routes = [{
    path: '/',
    component: Index,
    children: [
        {
            path: '/',
            component: Home,
            name: 'home'
        },
        {
            path: '/about',
            component: About,
            name: 'about'
        },
        {
            path: '/services',
            component: Services,
            name: 'services'
        },
        {
            path: '/culture',
            component: Culture,
            name: 'culture'
        },
        {
            path: '/products',
            component: Products,
            name: 'products'
        },
        {
            path: '/contact',
            component: Contact,
            name: 'contact'
        }
    ]
}]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router

