<template>
  <div class="footer">
    <p class="copyright">CopyRight 2023 版权所有 © 广州习创信息科技有限公司</p>
  </div>
</template>

<script>
export default {
  name: 'Footer'
};
</script>

<style lang="less" scoped>
.footer {
  height: 150px;
  width: 100%;
  background: rgba(247, 248, 251);
  border-top: 3px rgba(0, 132, 206) solid;
  display: flex;
  justify-content: center;
  align-items: center;
  .copyright {
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>