<template>
  <div class="index" id="top">
    <div class="menu">
      <div class="container">
        <Menu></Menu>
      </div>
    </div>
    <div class="shadow"></div>
    <div class="content">
      <Content></Content>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
    <div class="toolbar">
      <div class="box tel">
        <div class="phone">13650668248</div>
        <img src="@/assets/24gl-phoneLoudspeaker.png" alt="" />
      </div>
      <div class="box"><img src="@/assets/erweima.png" alt="" /></div>
      <div
        class="backtop"
        v-show="showBacktop"
        :class="{ show: showBacktop }"
        @click="scrollToTop"
      >
        <img src="@/assets/arrow.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Menu from './menu/Index.vue';
import Content from './content/Index.vue';
import Footer from './footer/Index.vue';
export default {
  name: 'Index',
  components: {
    Menu,
    Content,
    Footer
  },
  data() {
    return {
      showBacktop: false
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      this.showBacktop = scrollTop > 500;
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style lang="less" scoped>
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
.container {
  width: 70%;
}
.index {
  .menu {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 10;
    background: rgba(255, 255, 255);
  }
  .shadow::before {
    content: '';
    box-shadow: 0 0 10px 1px #333;
    position: fixed;
    width: 100%;
    z-index: 5;
  }
  .shadow::after {
    content: '';
    width: 100%;
    height: 15px;
    background: #fff;
    position: absolute;
    z-index: 6;
  }
  .toolbar {
    position: fixed;

    width: 60px;
    height: 184px;
    right: 35px;
    bottom: 160px;

    .box {
      width: 60px;
      height: 60px;
      box-sizing: border-box;
      padding: 10px;
      background: rgba(0, 132, 206);
      margin-bottom: 2px;
      opacity: 0.8;
      cursor: pointer;
      transition: opacity 0.3s;
      &:hover {
        opacity: 1;
      }

      img {
        height: 40px;
        width: 40px;
      }
    }
    .tel {
      position: relative;
      .phone {
        visibility: hidden;
        position: absolute;
        width: 200px;
        height: 60px;
        left: -200px;
        top: 0;
        font-size: 24px;
        color: #fff;
        line-height: 60px;
        text-align: center;
        background: rgba(0, 132, 206);
        transform: translateX(10px);
        opacity: 0;
        transition: visibility 0.3s, transform 0.3s, opacity 0.3s ease-in-out;
      }
    }
    .tel:hover .phone {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
    .backtop {
      width: 100%;
      height: 60px;
      box-sizing: border-box;
      padding: 15px;
      background: rgba(0, 132, 206);
      opacity: 0.8;
      transition: opacity 0.3s;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
      img {
        height: 30px;
        width: 30px;
      }
    }
    .show {
      animation: fadeIn 0.2s ease-in-out;
    }
  }
}
</style>