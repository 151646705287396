<template>
  <div>
    <div class="home-img">
      <img src="@/assets/xichuang.png" alt="" />
    </div>
    <div class="home-content">
      <div class="container">
        <h1 class="title">主营业务</h1>
        <p class="desc">
          广州习创信息科技有限公司是一家专注于赛项技术支持领域的企业。多年以来，我们一直致力于为国家级、省级和市级选拔工作提供优质的技术支持和保障服务，同时也为各大院校提供了系统全面、实用性强的服务。
        </p>
        <div class="line"></div>
        <div class="common">
          <el-row :gutter="20">
            <el-col
              :span="6"
              v-for="(item, index) in businessInfo"
              :key="index"
            >
              <el-card
                shadow="hover"
                @click.native="$router.push({ name: 'services' })"
              >
                <img :src="item.imgUrl" class="image" />
                <p class="card-title">{{ item.title }}</p>
                <p class="card-info">{{ item.info }}</p>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <h1 class="title">服务项目</h1>
        <p class="desc">
          在未来，我们将继续坚持技术创新和产品升级，为客户提供更加智能、高效、便捷的服务体验。
        </p>
        <div class="line"></div>
        <div class="common">
          <el-row :gutter="20">
            <el-col
              :span="6"
              v-for="(item, index) in servicesInfo"
              :key="index"
            >
              <el-card shadow="hover" @click.native="cardClick">
                <img :src="item.imgUrl" class="services-image" />
                <p class="card-info">{{ item.info }}</p>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <h1 class="title">合作伙伴</h1>
        <div class="company">
          <div class="company-row">
            <div class="company-icon">
              <img src="@/assets/huawei.png" alt="" />
            </div>
            <div class="company-icon">
              <img src="@/assets/h3c.png" alt="" />
            </div>
            <div class="company-icon">
              <img src="@/assets/ruijie.png" alt="" />
            </div>
            <div class="company-icon">
              <img src="@/assets/shenzhou.png" alt="" />
            </div>
          </div>
          <div class="company-row">
            <div class="company-icon">
              <img src="@/assets/dell.png" alt="" />
            </div>
            <div class="company-icon">
              <img src="@/assets/lenovo.png" alt="" />
            </div>
            <div class="company-icon">
              <img src="@/assets/hp.png" alt="" />
            </div>
            <div class="company-icon">
              <img src="@/assets/acer.png" alt="" />
            </div>
          </div>
          <div class="company-row">
            <div class="company-icon">
              <img src="@/assets/360.png" alt="" />
            </div>
            <div class="company-icon">
              <img src="@/assets/qiming.png" alt="" />
            </div>
            <div class="company-icon">
              <img src="@/assets/cisco.png" alt="" />
            </div>
            <div class="company-icon">
              <img src="@/assets/sangfor.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import business1 from '@/assets/business1.png';
import business2 from '@/assets/business2.png';
import business3 from '@/assets/business3.png';
import business4 from '@/assets/business4.png';
import services1 from '@/assets/services1.png';
import services2 from '@/assets/services2.png';
import services3 from '@/assets/services3.png';
import services4 from '@/assets/services4.png';
export default {
  name: 'Home',
  data() {
    return {
      businessInfo: [
        {
          imgUrl: business1,
          title: '教学资源开发',
          info: '结合职业教育的特点，提供丰富案例，系统开发教学资源，打造教学资源软件平台。'
        },
        {
          imgUrl: business2,
          title: '实验室规划建设服务',
          info: '高效、专业设计咨询服务团队及体系，全力打造最安全、最舒适、可靠的实验环境。'
        },
        {
          imgUrl: business3,
          title: '专业建设',
          info: '提供高水平职业教育、面向未来、适应需求、理念先进的专业建设服务，包括培养计划的制定、教材的制定和师资力量培养等。'
        },
        {
          imgUrl: business4,
          title: '赛项技术支持服务',
          info: '我们提供高质量的赛项技术支持服务，为客户提供全面、专业的竞赛技术支持和解决方案。'
        }
      ],
      servicesInfo: [
        {
          imgUrl: services1,
          info: '我们提供教学资源开发服务，包括课程设计、教材编写、在线课程制作等，为教育机构和企业培训提供全面的支持。'
        },
        {
          imgUrl: services2,
          info: '我们提供专业建设服务，包括系统集成、项目管理、技术支持等服务，帮助用户实现各种IT建设目标。'
        },
        {
          imgUrl: services3,
          info: '我们提供实验室规划建设服务，帮助用户设计、建设和管理高效、稳定的实验室环境，满足各种需求。'
        },
        {
          imgUrl: services4,
          info: '我们提供专业的赛项技术支持服务，包括在线咨询、培训课程和实践代理等多种服务，为用户提供全方位的技术支持和指导。'
        }
      ]
    };
  },
  methods: {
    cardClick() {
      this.$router.push({
        name: 'services',
        query: {
          name: '服务项目'
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.home-img {
  height: 480px;
  width: 1200px;
  margin: 0 auto;

  img {
    height: 100%;
    width: 100%;
  }
}
.home-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 30px;
    .title {
      font-size: 32px;
      font-weight: 600;
      line-height: 45px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 10px;
    }
    .desc {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 25px;
    }
    .line {
      width: 348px;
      height: 3px;
      background: rgba(0, 132, 206);
      margin-bottom: 25px;
    }
    .common {
      box-sizing: border-box;
      width: 100%;
      margin-bottom: 60px;
      & /deep/ .el-card {
        border-radius: 10px;
        height: 275px;
        cursor: pointer;
      }
      & /deep/ .el-card__body {
        padding: 14px;
        text-align: center;
      }

      & /deep/ .el-card.is-hover-shadow:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
      }
      .image {
        width: 100%;
      }
      .card-title {
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        margin-top: 6px;
      }
      .card-info {
        font-size: 13px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        text-align: left;
        margin-top: 6px;
      }
      .services-image {
        text-align: center;
        width: 170px;
      }
    }
    .company {
      box-sizing: border-box;
      width: 1417px;
      max-width: 100%;
      min-width: 100%;
      height: 350px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .company-row {
        width: 800px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .company-icon {
          width: 20%;
          height: 100%;
          opacity: 0.5;
          transition: opacity 0.2s linear;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}
</style>