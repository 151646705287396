import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import 'normalize.css'
import './assets/css/reset.css'

import {
  Row,
  Col,
  Card,
  Tabs,
  TabPane,
  Image
} from 'element-ui'


Vue.config.productionTip = false

Vue.use(Row);
Vue.use(Col);
Vue.use(Card);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Image)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
